<template>
  <div class="header-section" v-if="title||subtitle||paragraph">
    <div>
      <h1 class="title" v-if="title">{{ title }}</h1>
    </div>
    <div>
      <h1 class="subtitle" v-if="subtitle">{{ subtitle }}</h1>
    </div>
    <template v-if="paragraph">
      <div class="paragraph" v-html="paragraph"></div>
    </template>
  </div>
  
  <div class="grid-wrapper p-0">
  <div class="row justify-content-center" v-if="cards">
    <div class="col-12" :class="`col-lg-${cards.length>3?3:12/cards.length}`" v-for="card in cards" :key="card.imgSrc">
      <div class="grid-cell">
        <img :src="card.imgSrc" class="mini-img" />
        <h4 class="tileTitle">{{ card.tileTitle }}</h4>
        <div v-html="card.tileParagraph"></div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props: [
    "cards",
    "title",
    "subtitle",
    "paragraph",
    "imgSrc",
    "tileTitle",
    "tileParagraph",
  ],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.header-section  {
  .paragraph {
    padding: 0 75px;
    @include responsive(phone){
      padding: 0;
    }
  }
    .title {
      max-width: 100%;
    }
}

.grid-wrapper {
  display: flex;
  justify-content: center;
  @include responsive(phone) {
   display: block;
   padding: 0 1em;
  }
  @include responsive(tablet) {
   display: block;
  }
}

.title {
  padding-bottom: 30px;
  font-size: 48px;
  color: $primary-color-5;

}

.paragraph {
  padding: 0 75px;
}

.grid {
  display: flex;
  justify-content: center;
 @include responsive(phone) {
  }
  @include responsive(tablet) {
  }

}


.grid-cell {
  @include padding(0 2 0 0);

  @include responsive(phone) {
    flex: 1 0 100%;

    @include padding(2 0);
  }
  @include responsive(tablet) {
    flex: 0 0 100%;
     @include padding(2 0)
  }
}
</style>