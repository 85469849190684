<template>
<Alert :isActive="isError" v-if="isError">{{errorMessage}}</Alert>
<Loading :isShow="isLoading" v-if="!isError">
  <Navigation></Navigation>
  <div class="theme-container" v-if="!isLoading">
    <PageTitle
      :title="pageTitle"
      :imgSrc="headerImage"
    ></PageTitle>
    <template v-if="headerPretext">
      <div class="sectionContainer pretext" >
        <div class="container" v-html="headerPretext"></div>
      </div>
    </template>
    <div class="sectionContainer" v-if="headerTitle||headerDescription"> 
      <div class="container">
        <h1 v-if="headerTitle" class="main-title"> {{headerTitle}} </h1>
        <template v-if="headerDescription">
          <div class="mt-3" v-html="headerDescription"></div>
        </template>
      </div>
    </div>
      <div class="partnershipContainer" v-if="timeline">
        <div class="container">
          <h1 v-if="timeline.subtitle"> {{timeline.subtitle}} </h1>
          <template v-if="timeline.subdescription">
            <p class="mt-3" v-html="timeline.subdescription"></p>
          </template>
          <div class="flexContainer">
            <div class="flex-12" v-if="timeline.timelineEvents">
              <Timeline :timelineEvents="timeline.timelineEvents" :ascending="false" :yearCutoff="2017" />
            </div>
          </div>
        </div>
      </div>
      <div class="sectionContainer listeningSection" v-if="columnSection">
        <div class="container">
          <div style="margin-bottom: 35px;">
            <SurveyResultsCard
              :title="columnSection.title"
              :paragraph="columnSection.description"
            />
          </div>
          <div>
            <SurveyResultsCard
            :cards="columnSection.subsections"
          />
          </div>
        </div>
      </div>
      
      <div class="sectionContainer inv measuringProgressSection" v-if="measuringProgressSection">
        <div class="container">
          <h1 class="title-measuring">{{ measuringProgressSection.title }}</h1>
          <h3 class="paragraph">{{ measuringProgressSection.subtitle }}</h3>
          <div class="flex-container">
            <template v-for="surveySection in measuringProgressSection.surveySection">
              <div class="flex-6">
                <div class="subHeader">
                  {{ surveySection.title }}
                </div>
                <div class="grid measuring-card-set">
                  <div class="grid-row">
                    <div class="flex-6" v-for="card in surveySection.cards" :key="card.title">
                      <div class="card-wrapper">
                        <MeasuringCard :card="card" />
                      </div>
                    </div>
                    <template v-for="expandedCard in surveySection.expandedCards">
                      <div class="flex-12 graph-card">
                        <div class="card-wrapper">
                          <div class="grid-cell">
                            <div class="grid-content">
                              <h1 class="cardHeading">
                                {{ expandedCard.title }}
                              </h1>
                              <div class="graph-wrapper">
                                <div class="leftSide-graph">
                                  <div class="number">
                                    {{ expandedCard.currentValue}}
                                    <!--<span><img
                                        class="triangle-img"
                                        src="@/assets/images/survey/triangle.png"
                                        alt=""/></span>-->
                                  </div>
                                  <!--<div class="subtitle2">
                                    {{ expandedCard.previousValue }}
                                  </div>
                                  <div class="subtitleYear">{{ expandedCard.year}}</div>-->
                                </div>
                                <div class="rightSide-graph">
                                  <ul style="list-style: none" class="graph">
                                    <li>
                                      <span class="circle circle-color-one"></span
                                      ><span class="graph-rating">Great:</span
                                      ><span class="percentage">{{ expandedCard.greatValue }}</span>
                                    </li>
                                    <li>
                                      <span class="circle circle-color-two"></span
                                      ><span class="graph-rating">Good:</span
                                      ><span class="percentage">{{ expandedCard.goodValue}}</span>
                                    </li>
                                    <li>
                                      <span class="circle circle-color-three"></span
                                      ><span class="graph-rating">Fair:</span
                                      ><span class="percentage">{{ expandedCard.fairValue }}</span>
                                    </li>
                                    <li>
                                      <span class="circle circle-color-four"></span
                                      ><span class="graph-rating">Poor:</span
                                      ><span class="percentage">{{ expandedCard.poorValue }}</span>
                                    </li>
                                  </ul>
                                  <div class="bar">
                                    <div class="bar-inner blue" :style="{height: expandedCard.greatValue + '%' }"></div>
                                    <div class="bar-inner green" :style="{height: expandedCard.goodValue + '%' }"></div>
                                    <div class="bar-inner yellow" :style="{height: expandedCard.fairValue + '%' }"></div>
                                    <div class="bar-inner red" :style="{height: expandedCard.poorValue + '%' }"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    </Loading>
  <BottomNavigator :id='timelinePageId'/>
</template>

<script>
import BottomNavigator from '../components/BottomNavigator.vue'
import Loading from "@/components/Loading.vue";
import Alert from "@/components/Alert.vue"
import PageContainer from "@/components/PageContainer.vue";
import Navigation from "@/components/Navigation.vue";
import PageTitle from "@/components/PageTitle.vue";
import Timeline from "@/components/Timeline.vue";
import api from '@/services/api.js';
import SurveyResultsCard from '@/components/SurveyResultsCard.vue'
import MeasuringCard from '@/components/MeasuringCard.vue'

export default {
  data: () => ({
		isLoading: false,
		isError: false,
		errorMessage: '',
    pageTitle: "",
    headerImage: "",
    headerPretext: "",
    headerTitle: "",
    headerDescription: "",
	}),
  computed: {
    timelinePageId() {
      const timelinePage = this.$store.state.pages.timeline;
      if(timelinePage){
        return timelinePage.id;
      }
      return null;
    },
  },

  methods: {
		async getData() {
			this.isError = false;
			this.isLoading = true;
			try {
        const { data } = await api.getTimelinePage(this.timelinePageId);
        this.timeline = {
          ...data.timeline,
          subtitle: data.timeline.subtitle,
          subdescription: data.timeline.subdescription?.text || '',
        }
        this.measuringProgressSection = {
          ...data.measuringProgressSection,
          surveySection: data.measuringProgressSection.surveySection.map(surveySection => ({
            ...surveySection,
            cards: surveySection.cards.map(card => ({
              title: card.title,
              subtitle: card.currentValue,
              subtitle2: card.previousValue,
              year: card.year
            }))
          }))
        }
        this.pageTitle = data.pageTitle;
        this.headerImage = data.pageIcon;
        this.headerPretext = data.pageDescription?.text || '';
        this.headerTitle = data.timeline.title;
        this.headerDescription = data.timeline.description?.text || '';
        this.columnSection = {
          ...data.columnSection,
          description: data.columnSection.description?.text || '',
          subsections: data.columnSection.subsections.map((subsection)=>({
            imgSrc: subsection.icon,
            tileTitle: subsection.title,
            tileParagraph: subsection.description?.text || ''
          })),

        };
			} catch (err) {
				this.isError = true;
				this.errorMessage = err.message;
			} finally {
				this.isLoading = false;
			}
		},
	},
  watch:{
    timelinePageId(newValue, oldValue) {
      if (newValue) {
        this.getData()
      }
    }
  }, 
	async beforeRouteUpdate(to, from, next) {
		//await this.getData();
		next();
	},

	async created() {
    if(this.timelinePageId){
		  await this.getData();
    }
	},

  components: {
    BottomNavigator,
    Loading,
    PageContainer,
    Timeline,
    Navigation,
    PageTitle,
    Alert,
    SurveyResultsCard,
    MeasuringCard,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.theme-container {
  @include relative;
  @include margin(0 0 10 0);
  :deep(.page-title) {
    .title-container{
      background-color: $primary-color-4;
    }
  }
}

.partnershipContainer{
  background-color: $secondary-color-4;
  @include padding(8);
  @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.sectionContainer{
  &.pretext{
    padding-bottom:0px;
  }
  background-color: $secondary-color-9;
  @include padding(8);
  @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.inv {
  background-color: $secondary-color-4;
}

.main-title {
  color: $primary-color-4;
  font-size: 36px;
  @include responsive(phone) {
    font-size: 38px;
  }
}

.leftSide-graph,
.rightSide-graph {
}
.rightSide-graph {
  min-height: 100px;
  justify-content:center;
}
.graph-wrapper{
  display:flex;
  justify-content: space-between;
}
.graph {
  display: inline-block;
  margin-bottom:0px;
  padding-left:0px;
}

.bar {
  display: inline-block;
  width: 20px;
  height: 85px;
  border-radius: 2px;
  margin-left: 25px;
  overflow: hidden;
}

.bar-inner {
  width: 100%;
}

.yellow {
  background-color: $secondary-color-3;
}

.blue {
  background-color: $secondary-color-1;
}

.green {
  background-color: $secondary-color-2;
}

.red {
  background-color: $secondary-color-6;
}

.circle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 10px;
}

.square {
  width: 15px;
  display: inline-block;
  margin-left: 40px;
}

.circle-color-one {
  background-color: $secondary-color-1;
}

.circle-color-two {
  background-color: $secondary-color-2;
}

.circle-color-three {
  background-color: $secondary-color-3;
}

.circle-color-four {
  background-color: $secondary-color-6;
}

.graph-rating {
  margin-right: 30px;
}

.measuring-card-set{
  padding-left: 1em;
  padding-right:1em;
  @include responsive(phone){
      padding-left: 0;
      padding-right:0;
  }
  @include responsive(tablet){
    .flex-6{
      flex: 0 6 50%;
    }
  }
}
.grid {
  display: flex;
  .grid-row{
    display:flex;
    flex-wrap: wrap;
      justify-content: center;
      .card-wrapper{
        padding:15px;
          @include responsive(phone){
            padding-left: 0;
            padding-right:0;
        }
      }
      .grid-cell {
        background-color: $secondary-color-9;
        border-radius: 8px 8px 48px 8px;
        box-shadow: 0px 4px 8px rgba(208, 215, 217, 0.4);
        overflow: hidden;
        position: relative;
        padding: 20px;
      }
  }
}

.grid-content, :deep(.grid-content)   {
  padding-top: 10px;
  padding-bottom: 20px;
  min-height:180px;
}

.percentage {
  float: right;
  color: $secondary-color-8;
  font-size: 16px;
  font-weight: bold;
}

.cardHeading {
  color: $secondary-color-7;
  font-size: 16px;
  height: 50px;
}

.title-measuring {
  color: $primary-color-5;
  padding-bottom: 30px;
  font-size: 48px;
}

.subHeader {
  padding-left: 35px;
  color: $secondary-color-1;
  font-weight: 600;
  @include responsive(phone){
    padding-left: 10px;
  }
}

.subtitleYear {
  color: $secondary-color-8;
  font-size: 16px;
}

.subtitle2 {
  color: $secondary-color-8;
  font-size: 16px;
  font-weight: bold;
}

.number {
  color: $secondary-color-7;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
}

.listeningSection{
  :deep(.header-section){
    .paragraph{
      padding: 0px;
    }
  }
}
.paragraph {
  @include margin(2);
  font-size: 28px;
  @include responsive(phone){
    @include margin(0);
    margin-bottom: 20px;
    font-size: 20px;
  }
}

.triangle-img {
  padding-left: 15px;
}

</style>
