<template>
  <div class="card-wrapper">
    <div class="event-line event-content"></div>
    <div class="event-content">
      <header class="card__header">
          <span v-if="event.title" class="card__title r-title">{{event.title}}</span>
      </header>
      <div class="card__content">
          <div v-on:click="toggleExpand" :class="`event__name ${event.details ? 'clickable' : ''}`"><span>{{event.desc}}</span><span v-if="event.details"><i :class="`fa-solid fa-chevron-down icon ${!isCollapsed ? 'flip' : ''}`"></i></span></div>
          <template v-if="event.details">
              <div :class="`${isCollapsed ? 'collapsed' : ''} card__description`" v-html="event.details.text"></div>
          </template>
      </div>
    </div>
  </div>
</template>

<script>


export default {
	data: () => ({
    isCollapsed: true
	}),
    props: {
        event:{type: Object, default: {title: '', desc: '', details: ''}},
    },

  methods:{
    toggleExpand: function () {
      this.isCollapsed = !this.isCollapsed;
    }
  },
	components: {

	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_settings';
@import '@/assets/scss/mixins';


  .time__day{
    font-size: 1.5em;
    color: $primary-color;
    font-weight:600;
  }
  .card-wrapper{
    display:flex;
    padding-top:15px;
    padding-bottom:15px;
  }
  .event-content{
    .event__name{
      display:flex;
      justify-content: space-between;
    }

    .icon{
      transition-duration: .5s;
      &.flip{
        transform: rotate(180deg);
      }
    }
  }
  .event-line{
    border:2px solid get-color($secondary-color-4);
    margin-left:15px;
    margin-right:15px;
  }
  .r-title{
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1;
  }
  .card__content{
    .card__description{
      font-size: .8em;
      overflow:hidden;
      transition: max-height 1s ease-in-out;
      max-height: 1500px;
      &.collapsed{
        max-height:0px;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
      }
    }
  }
.clickable{
  &:hover{
    cursor: pointer;
  }
}
</style>