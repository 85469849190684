<template>
    <div class="timeline">
      <div class="tabs">
        <div class="timeline__group" v-for="(eventsByYear, index) in events" v-on:click="setActiveTab(index)" :key="eventsByYear.year">
          <div class="tab-wrapper" :class="{last:events.length-1==index}">
            <div class="timeline__year time" :class="{active:activeTab==index}" aria-hidden="true" >{{eventsByYear.year}}</div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="timeline__group" v-for="(eventsByYear, index) in events" v-on:click="setActiveTab(index)" :key="eventsByYear.year">
          <div class="timeline__year time title" :class="{active:activeTab==index}" >{{eventsByYear.year}}</div>
          <div class="timeline__cards" v-if="activeTab==index">
              <div class="timeline__card tl_card" v-for="eventsByMonth in eventsByYear.events" :key="eventsByMonth.month">
                <header>
                  <span class="time__day">{{eventsByMonth.month}} {{eventsByMonth.year}}</span>
                </header>
                <template v-for="event in eventsByMonth.events" :key="event.title" >
                  <template v-if="event">
                    <TimelineEvent :event="event"/>
                  </template>
                </template>
              </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import TimelineEvent from "@/components/TimelineEvent.vue";

export default {
	data: () => ({
		isLoading: false,
    activeTab: 0,
	}),
    props: {
        ascending:{type: Boolean, default: true},
        timelineEvents:Array,
        yearCutoff:{type: Number, default: null}
    },
    computed:{
        events() {
            var tempEvents = new Array();
            let tempEventsSorted;
            if(this.timelineEvents){

                var tempArray = new Array();
                if(this.ascending){
                    tempEventsSorted = this.timelineEvents.sort((a,b) => (new Date(a.date) - new Date(b.date)))
                }
                else{
                    tempEventsSorted = this.timelineEvents.sort((a,b) => (new Date(b.date) - new Date(a.date)))
                }
                var currentYear = null;
                var currentYearActual = null;
                var currentMonth = null;
                var tempEventsInMonth = null;
                var tempEventsInYear = null;
                for(let i = 0;i<tempEventsSorted.length; i++){
                  const currentEvent = tempEventsSorted[i];
                  let currentDate = new Date(currentEvent.date);
                  let currentDateYear = currentDate.getFullYear();
                  let currentDateYearActual = currentDate.getFullYear();
                  if(this.yearCutoff && ((this.ascending && (currentDate.getFullYear() >= this.yearCutoff ))||(!this.ascending && ((currentDate.getFullYear() <= this.yearCutoff))))){
                    currentDateYear = this.yearCutoff + "+";
                  }
                  if (currentYear != currentDateYear ){
                    if(currentYear != null){
                      tempEventsInYear.push({
                        month:currentMonth,
                        year: currentYearActual,
                        events:tempEventsInMonth,
                      })
                      tempEvents.push({
                          year:currentYear,
                          events:tempEventsInYear
                      })
                    }
                    var currentMonth = null;
                    var tempEventsInMonth = null;
                    currentYear = currentDateYear;
                    tempEventsInYear = new Array();
                  }
                  if (currentMonth != this.getShortMonth(currentDate) || currentYearActual != currentDateYearActual){
                    if(currentMonth != null){
                        tempEventsInYear.push({
                            month:currentMonth,
                            year: currentYearActual,
                            events:tempEventsInMonth
                        })
                    }
                    currentYearActual = currentDateYearActual;
                    currentMonth = this.getShortMonth(currentDate);
                    tempEventsInMonth = new Array();
                  }
                  tempEventsInMonth.push({...currentEvent, jsDate: currentDate})
                  if(i+1 == tempEventsSorted.length){
                    tempEventsInYear.push({
                      month:currentMonth,
                      year: currentYearActual,
                      events:tempEventsInMonth
                    })
                    tempEvents.push({
                      year:currentYear,
                      events:tempEventsInYear
                    })
                  }
                }
            }
            return tempEvents;
        }
    },
  methods:{
    getShortMonth: (date)=>{
      var options = { month: 'short'};
      return new Intl.DateTimeFormat('en-US', options).format(date)
    },
    setActiveTab: function(index) {
      this.activeTab = index;
    } 
  },
	components: {
    TimelineEvent
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_settings';
@import '@/assets/scss/mixins';

.timeline{
  display:flex;
}
.tabs{
  flex: 0 4 33.33333%;
  padding-right:4em;
  @include responsive(phone) {
    display:none;
  }
  .tab-wrapper{
      border-bottom: 1px solid map-get($colors, slalom-medium-gray);
      padding-top: 5px;
      padding-bottom: 5px;
      
      &.last{
        border-bottom:0px;
      }
  }
}
.content{
  padding-top: 5px;
  flex: 0 8 66.66667%;
  @include responsive(phone) {
    flex: 0 8 100%;
    padding-top: 0px;
  }
  .time{
    margin-right: 0.25em;
    margin-left: 0.25em;
  }
}
.time{
  border-radius:8px;
  padding:5px 15px 5px 15px;
  font-size:2em;
  cursor:pointer;
    &.active{
      background-color:white;
      color: $primary-color;
      font-weight:600;
  }
  &.title{
    display:none;
    @include responsive(phone) {
      display:block;
    }
  }
}
.tl_card{
  background-color:$secondary-color-9;
  @include padding(3 4 3 4);
  margin-bottom:1em;
  border-bottom-right-radius: 4em;
  position: relative;
  box-shadow: 0px 4px 8px rgba(208, 215, 217, 0.4);
  @include responsive(phone) {
    @include margin(4 1 4 1);
    @include padding(4 2 4 2);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
  .time__day{
    font-size: 1.5em;
    color: $primary-color;
    font-weight:600;
  }
  .card-wrapper{
    display:flex;
    padding-top:15px;
    padding-bottom:15px;
  }
  .event-content{
  }
  .event-line{
    border:2px solid get-color($secondary-color-4);
    margin-left:15px;
    margin-right:15px;
  }
  .r-title{
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1;
  }
  .card__content{
    .card__description{
      font-size: .8em;
    }
  }
}
</style>