<template>
 
    <div class="grid-cell" >
      <div class="grid-content">
        <h1 class="cardHeading">{{ card.title }}</h1>
        <div class="number">
          {{ card.subtitle}}
          <!--<span>
            <img v-if="card.subtitle > card.subtitle2" class="triangle-img" src="@/assets/images/survey/triangle.png" alt=""/>
            <img v-if="card.subtitle < card.subtitle2" class="triangle-img" src="@/assets/images/survey/downTriangle.png" alt=""/>
          </span>-->
        </div>
        <!--<div class="subtitle2">{{ card.subtitle2 }}</div>
        <div class="subtitleYear">{{card.year}}</div>-->
      </div>
    </div>

</template>

<script>
export default {
  props: ["card", "cardsTitle", "subtitle", "subHeader"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.grid-cell {
  background-color:$secondary-color-9;
  border-radius: 8px 8px 48px 8px;
  box-shadow: 0px 4px 8px rgba(208, 215, 217, 0.4);
  overflow: hidden;
  position: relative;
  padding: 20px;
}

.grid-content {
  padding-top: 10px;
  padding-bottom: 20px;
}

.subtitle2 {
  color: $secondary-color-8;
  font-size: 16px;
  font-weight: bold;
}

.subtitleYear {
  color: $secondary-color-8;
  font-size: 16px;
}

.number {
  color: $secondary-color-7;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
}

h1 {
  color: $secondary-color-7;
  font-size: 16px;
  max-width: 100%;
}

h3 {
  padding: 30px 0;
  margin-left: 50px;
}

.cardsTitle {
  font-size: 32px;
}

.cardHeading {
  height: 50px;
}

.triangle-img  {
  padding-left: 15px;
}
</style>
